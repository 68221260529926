:root {
    --bg-color-1: #BBF3FF;
    --bg-color-2: #2474ED;
    --tone0: #cccccc;
    --tone1: #760000;
    --tone2: #E90E00;
    --tone3: #E77003;
    --tone4: #FFC346;
    --tone5: #FFF389;
    --tone6: #CEFCA1;
    --tone7: #6CF400;
    --tone8: #48A61E;
    --tone9: #2BDBBB;
    --tone10: #7BF7FF;
     
}

body, p {
    margin: 0px;
    font-family: 'Inter', serif;
}

button, input {
    border: none;
}

a {
    color: black;
    text-decoration: none;
}

h1 {
    font-weight: 400;
    font-size: 4rem;
    color: var(--bg-color-2);
    margin: 0px;
} h2 {
    font-weight: 300;
    font-size: 3rem;
    margin: 0px;
} h3 {
    font-weight: 400;
    font-size: 1.5rem;
    margin: 0px;
}

.background {
    height: auto;
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(to bottom, var(--bg-color-1), var(--bg-color-2));
    overflow: auto;
}

.spacedRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.centeredColumn{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.yearCalendarGrid {
    display: grid;
    grid-template-columns: repeat(6, 15vw);
    grid-template-rows: repeat(2, 31.5vh);
    grid-gap: 1.5vw 1vw;
}

.calendarGrid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 3.6vh;
    place-items: center;
}

.circleCell {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.shadowBox {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 3vh 2vw;
    border-radius: 3vw;
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
}

.inputBox {
    width: 35vw;
    min-height: 60vh;
    overflow-wrap: normal;
    margin: 0px;
    border: none;
    font-size: 1rem;
}

.monthBox{
    padding: 0.5vh 0.5vw;
    height: 100%; 
    border-radius: 1.5vw; 
    gap: 1vh;
}

.aboutBox{
    width: 25vw;
}

.fullMonthBox {
    width: 35vw;
}

.navButton {
    background-color: white;
    min-width: 12vw;
    font-size: 1.5rem;
    padding: 0.8rem;
    border-radius: 2.3rem;
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
}.navButton:hover {
    background-color: var(--bg-color-1);
    cursor: pointer;
}

.navArrow {
    width: 0;
    height: 0;
    padding: 0px;
    margin: 0vh;
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    border-bottom: 3rem solid var(--bg-color-2);
    background-color: transparent;
    cursor: pointer;
}

.checkbox {
    transform: scale(1.5);
}

.inputForm {
    display: flex;
    flex-direction: column;
    gap: 2vh
}

.loginInput {
    background-color: var(--bg-color-1);
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 1rem;
}

.loginButton {
    background-color: #62C7EA;
    min-width: 12vw;
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 1rem;
}.loginButton:hover {
    background-color: var(--bg-color-2);
    cursor: pointer;
}.loginButton:active {
    background-color: darkblue;
}

.aboutButton{
    position: absolute;
    right: 2vw;
    bottom: 3vh; 
}

.accountButton{
    position: absolute;
    right: 15vw;
    bottom: 3vh;
}

.goalButton{
    position: absolute;
    right: 2vw;
    top: 4vh;
}

.yearHeader{
    margin: 2vw;
}

.monthHeader{
    position: absolute;
    top: 1vh;
    left: 2vw;
}

.happyBar {
    margin-top: 2vh;
    padding: 0vh 2vw;
    width: 35vw;
}

.onlyMobileDisplay {
    display: none;
}

.darkGreenButton {
    background-color: #75D490;
}.darkGreenButton:hover {
    background-color: #0f782d;
}.lightGreenButton {
    background-color: #CCFAD9;
}.lightGreenButton:hover {
    background-color: #85eba2;
}

.pcCenter{
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 3rem;
    }
    h2{
        font-size: 2rem;
    }
    h3 {
        font-size: 1.2rem;
    }

    .checkbox {
        transform: scale(2);
        margin: 2vw;
    }

    .shadowBox{
        padding: 2vw;
        border-radius: 3vh;
    }

    .inputForm{
        gap: 1vh;
    }

    .loginInput{
        width: 70vw;
        font-size: 1rem;
    }

    .navArrow{
        margin: 2vh 4vh;
    }

    .aboutButton, .accountButton{
        position: fixed;
        bottom: 1vh;
    } .accountButton {
        right: 30vw;
    } .goalButton {
        position: static;
        right: 0vw;
        top: 0vh;
        margin: 1vh;
    }

    .yearCalendarGrid{
        grid-template-columns: repeat(1, 90vw);
        grid-template-rows: repeat(12, 45vh);
        grid-gap: 3vh;
        margin-bottom: 12vh;
    }

    .calendarGrid {
        grid-template-columns: repeat(7, 1fr);
        grid-auto-rows: 5.4vh;
        place-items: center;
        gap: 0.2vh;
    }

    .circleCell {
        border-radius: 50%;
        width: 100%;
        height: 80%;
    }
    
    .aboutBox {
        width: 80vw;
        padding: 3vh;
    }

    .fullMonthBox {
        width: 90vw;
    }

    .goalBox {
        width: 90vw;
    }

    .yearHeader {
        margin: 1vw;
    }

    .monthHeader {
        position: static;
    }

    .noMobileDisplay {
        display: none;
    }
    .noMobileMargin {
        margin: 0px;
    }
    .onlyMobileDisplay {
        display: inline-block;
    }

    .mobileColumn {
        flex-direction: column;
    }

    .pcCenter {
        justify-content: start;
    }

    .mobileShrink {
        transform: scale(0.7);
    } 

    .happyBar {
        width: 90vw;
    }

}